export default {
    created: function () {
     },
    methods: {
        detailUser: function (user) {
            switch(user.role) {
                case "vendor": 
                    return user.vendor;
                case "student": 
                    return user.student;
                case "teacher":
                    return user.teacher;
                default:
                    return user;
            }
        },
        getVendorId: function (user) {
            switch(user.role) {
                case "vendor": 
                    return user.vendor._id;
                case "student": 
                    return user.vendor_id;
                case "teacher":
                    return user.vendor_id;
                default:
                    return user;
            }
        }
     }
}