import ApiService from "@/core/services/api.service";

// Actions
export const GET_REKAP_NILAI = "getRekapNilai";
export const GET_ONE_REKAP_NILAI = "getOneRekapNilai";
export const CREATE_REKAP_NILAI = "createRekapNilai";
export const UPDATE_REKAP_NILAI = "updateRekapNilai";
export const EXPORT_REKAP_NILAI = "exportRekapNilai";
export const GET_HISTORI_NILAI = "getHistoriNilai";
export const GET_NILAI_SISWA = "getNilaiSiswa";
export const DELETE_REKAP_NILAI = "deleteRekapNilai";
export const CLEAR_REKAP_NILAI = "clearRekapNilai";

// Mutation
export const SET_REKAP_NILAI = "setRekapNilai";
export const SET_ONE_REKAP_NILAI = "setOneRekapNilai";
export const SET_ERROR = "setErrorRekapNilai";

const state = {
    rekapNilaiList: [],
    rekapNilai: {},
    errors: null
};

const getters = {
    currentRekapNilaiList(state) {
        return state.rekapNilaiList;
    },
    currentRekapNilai(state) {
        return state.rekapNilai;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_REKAP_NILAI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`pembobotan/${payload.id_kurikulum}`)
                .then(({ data }) => {
                    context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_NILAI_SISWA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/result/${payload}`)
                .then(({ data }) => {
                    context.commit(SET_ONE_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_HISTORI_NILAI](context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/my-result`)
                .then(({ data }) => {
                    context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_REKAP_NILAI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`penilaian/${payload.id_kurikulum}/${payload.id_ujian}`)
                .then(({ data }) => {
                    context.commit(SET_ONE_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_REKAP_NILAI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`ujian/${payload.id_ujian}/rekapNilai`, payload)
                .then(({ data }) => {
                    // context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_REKAP_NILAI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`pembobotan/${payload.id_kurikulum}`, payload)
                .then(({ data }) => {
                    // context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [EXPORT_REKAP_NILAI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`pembobotan/${payload.id_kurikulum}/export`, payload)
                .then(({ data }) => {
                    // context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_REKAP_NILAI](context, payload) {
        // console.log(payload)
        return new Promise((resolve, reject) => {
            ApiService.delete(`ujian/${payload.idUjian}/rekapNilai/${payload.id}`)
                .then(({ data }) => {
                    // context.commit(SET_REKAP_NILAI, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CLEAR_REKAP_NILAI](context) {
        context.commit(SET_ONE_REKAP_NILAI, {})
    },
    [SET_ONE_REKAP_NILAI](context, payload) {
        context.commit(SET_ONE_REKAP_NILAI, payload)
    },
};

const mutations = {
    [SET_REKAP_NILAI](state, rekapNilai_list) {
        state.rekapNilaiList = rekapNilai_list;
    },
    [SET_ONE_REKAP_NILAI](state, rekapNilai) {
        state.rekapNilai = rekapNilai;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
