var render = function render(){var _vm=this,_c=_vm._self._c;return _c('editor',{staticStyle:{"min-height":"100px"},attrs:{"api-key":_vm.apiKey,"initial-value":_vm.value,"init":{
    selector: 'textarea',
    min_height: 100,
    max_height: 250,
    height: 250,
    menubar: false,
    toolbar_mode: 'wrap',
    plugins: [
      'autoresize',
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
      'image'
      ,
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | image | removeformat | help',
    images_upload_url: `${_vm.uploadBaseURL}uploads/`,
    images_upload_base_path: `${_vm.uploadBaseURL}uploads/`,
    images_upload_handler: _vm.uploadHandler,
    images_upload_credentials: false,
  },"resize":"true"},on:{"input":_vm.onChange},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})
}
var staticRenderFns = []

export { render, staticRenderFns }