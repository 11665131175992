import ApiService from "@/core/services/api.service";

// Actions
export const GET_BANK_SOAL = "getBankSoal";
export const GET_BANK_SOAL_OUTSIDE = "getBankSoalOutside"
export const GET_ONE_BANK_SOAL = "getOneBankSoal";
export const CREATE_BANK_SOAL = "createBankSoal";
export const UPDATE_BANK_SOAL = "updateBankSoal";
export const DELETE_BANK_SOAL = "deleteBankSoal";
export const CLEAR_BANK_SOAL = "clearBankSoal";
export const IMPORT_BANK_SOAL = "importBankSoal";

// Mutation
export const SET_BANK_SOAL = "setBankSoal";
export const SET_BANK_SOAL_OUTSIDE = "setBankSoalOutside";
export const SET_ONE_BANK_SOAL = "setOneBankSoal";
export const SET_ERROR = "setErrorBankSoal";

const baseURL = `${process.env.VUE_APP_API_BASE_URL}api/`
export const GET_QUESTION_TEMPLATE = `${baseURL}export/question-template?vendor_id=`;

const state = {
    bankSoalList: [],
    bankSoal: {},
    errors: null,
    bankSoalListOutside : []
};

const getters = {
    currentBankSoalList(state) {
        return state.bankSoalList;
    },
    currentBankSoal(state) {
        return state.bankSoal;
    },
    currentBankListOutside(state) {
        return state.bankSoalListOutside;
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_BANK_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`question/paginate?${payload}`)
                .then(({ data }) => {
                    context.commit(SET_BANK_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_BANK_SOAL_OUTSIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`question/paginate-outside-question-pack?${payload}`)
                .then(({ data }) => {
                    context.commit(SET_BANK_SOAL_OUTSIDE, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_BANK_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`bank-soal/${payload.id}`)
                .then(({ data }) => {
                    context.commit(SET_ONE_BANK_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_BANK_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`bank-soal`, payload)
                .then(({ data }) => {
                    // context.commit(SET_BANK_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [IMPORT_BANK_SOAL](context, payload) {
        const formData = new FormData()
        for(let key in payload){
            formData.append(key, payload[key])
        }
        return new Promise((resolve, reject) => {
            ApiService.post(`import/question`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_BANK_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`bank-soal/${payload.id}`, payload)
                .then(({ data }) => {
                    // context.commit(SET_BANK_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_BANK_SOAL](context, payload) {
        // console.log(payload)
        return new Promise((resolve, reject) => {
            ApiService.delete(`question/${payload.id}`)
                .then(({ data }) => {
                    // context.commit(SET_BANK_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CLEAR_BANK_SOAL](context) {
        context.commit(SET_ONE_BANK_SOAL, {})
    },
    [SET_ONE_BANK_SOAL](context, payload) {
        context.commit(SET_ONE_BANK_SOAL, payload)
    },
};

const mutations = {
    [SET_BANK_SOAL](state, bankSoal_list) {
        state.bankSoalList = bankSoal_list;
    },
    [SET_ONE_BANK_SOAL](state, bankSoal) {
        state.bankSoal = bankSoal;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_BANK_SOAL_OUTSIDE](state, bankSoal_list) {
        state.bankSoalListOutside = bankSoal_list;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
