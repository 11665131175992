import ApiService from "@/core/services/api.service";
// import localforage from "localforage";
// import VuexPersistence from "vuex-persist";

// import JWTService from "@/core/services/jwt.service"

// Actions
export const GET_ATTEMPT = "getAttempt";
export const GRADE_ATTEMPT = "gradeAttempt";
export const GET_ONE_ATTEMPT = "getOneAttempt";
export const CREATE_ATTEMPT = "createAttempt";
export const UPDATE_ATTEMPT = "updateAttempt";
export const DELETE_ATTEMPT = "deleteAttempt";
export const CLEAR_ATTEMPT = "clearAttempt";
export const SET_STATE = "setStateAttempt";

// Mutation
export const SET_ATTEMPT = "setAttempt";
export const UPDATE_STATE = "updateStateAttempt";
export const SET_ONE_ATTEMPT = "setOneAttempt";
export const SET_ERROR = "setErrorAttempt";
export const SET_STATE_ATTEMPT = "setAttemptUjian";

export const QUESTION = "question";
export const SET_QUESTION = "setQuestion";

// localforage.config({
//     driver      : localforage.IndexedDB,
//     name        : 'SIUJI',
//     version     : 1.0,
//     storeName   : 'siuji'
// });
//
// const vuexPersist = new VuexPersistence({
//     // strictMode: true, // This **MUST** be set to true
//     key: 'attempt',
//     storage: localforage,
//     asyncStorage: true,
//     reducer: (state) => ({ attempt: state.attempt }),
//     // filter: (mutation) => mutation.type === 'dogBark'
// })

const state = {
    attemptList: [],
    attempt: {

    },
    questions: [],
    errors: null
};

const getters = {
    currentAttemptList(state) {
        return state.attemptList;
    },
    currentAttempt(state) {
        return state.attempt;
    },
    currentQuestions(state) {
        return state.questions;
    }

};

// const plugins = [
//     // createPersistedState({
//     //     paths: ['token', 'user', 'kode_unik', 'reset_code'],
//     //     key: 'auth',
//     //     storage: {
//     //         getItem: key => Cookies.get(key),
//     //         setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: false }),
//     //         removeItem: key => Cookies.remove(key)
//     //     },
//     // }),
//     vuexPersist.plugin
// ]

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("ujian?" + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_ATTEMPT, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("exams/take/" + payload)
                .then(({
                    data
                }) => {
                    const _data = data.data
                    var date = new Date()
                    _data.tab_change = 0
                    // this.now = Math.trunc(date.getTime()/1000)
                    date.setMinutes(date.getMinutes() + _data.time)
                    _data.target = Math.trunc(date.getTime() / 1000)
                    _data.end = date

                    context.commit(SET_ONE_ATTEMPT, _data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("attempt", payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_ATTEMPT, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GRADE_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`exams/submit/${payload._id}`, payload.body)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_ATTEMPT, {});
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    // if(response.status == 400) {
                    // context.commit(SET_ONE_ATTEMPT, {});
                    // }

                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("attempt/" + payload.id, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_ATTEMPT, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_ATTEMPT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("attempt/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_ATTEMPT, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CLEAR_ATTEMPT](context) {
        context.commit(SET_ONE_ATTEMPT, {})
    },
    [SET_STATE](context, payload) {
        context.commit(UPDATE_STATE, payload)
    },
    [QUESTION](context, payload) {
        context.commit(SET_QUESTION, payload);
    }
};

const mutations = {
    [SET_ATTEMPT](state, attempt_list) {
        state.attemptList = attempt_list;
    },
    [SET_ONE_ATTEMPT](state, attempt) {
        state.attempt = attempt;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [UPDATE_STATE](state, data) {
        Object.assign(state, {
            ...data,
        })
    },
    [SET_STATE_ATTEMPT](state, data) {
        Object.assign(state.attempt, {
            ...data,
        })
    },
    [SET_QUESTION](state, data) {
        state.questions = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
    // plugins
};