<template>
  <editor
    v-model="content"
    :api-key="apiKey"
    @input="onChange"
    :initial-value="value"
    style="min-height: 100px"
    :init="{
      selector: 'textarea',
      min_height: 100,
      max_height: 250,
      height: 250,
      menubar: false,
      toolbar_mode: 'wrap',
      plugins: [
        'autoresize',
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'image'
        ,
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | image | removeformat | help',
      images_upload_url: `${uploadBaseURL}uploads/`,
      images_upload_base_path: `${uploadBaseURL}uploads/`,
      images_upload_handler: uploadHandler,
      images_upload_credentials: false,
    }"
    resize="true"
  />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import ApiService from "@/core/services/api.service";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "TinyEditor",
  props: {
    value: String,
    required: Boolean,
    type: String,
  },
  data() {
    return {
      content: null,
    };
  },
  components: {
    Editor,
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_TINY_MCE_API_KEY;
    },
    apiBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    uploadBaseURL() {
      return process.env.VUE_APP_UPLOAD_BASE_URL;
    },
  },
  mounted() {
    this.content = this.value;
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    // eslint-disable-next-line no-unused-vars
    async uploadHandler(blobInfo, success, failure, progress) {
      var formData;
      // const user = JSON.parse(JwtService.getToken())
      // xhr = new XMLHttpRequest();
      // xhr.withCredentials = true;
      // xhr.open('POST', `${this.apiBaseURL}v1/upload-image`);
      // xhr.setRequestHeader('Authorization', `Bearer ${user.access_token}`);
      // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      //
      // xhr.upload.onprogress = function (e) {
      //   progress(e.loaded / e.total * 100);
      // };
      //
      // xhr.onload = function() {
      //   var json;
      //
      //   if (xhr.status === 403) {
      //     failure('HTTP Error: ' + xhr.status, { remove: true });
      //     return;
      //   }
      //
      //   if (xhr.status < 200 || xhr.status >= 300) {
      //     failure('HTTP Error: ' + xhr.status);
      //     return;
      //   }
      //
      //   json = JSON.parse(xhr.responseText);
      //
      //   if (!json || typeof json.location != 'string') {
      //     failure('Invalid JSON: ' + xhr.responseText);
      //     return;
      //   }
      //
      //   success(json.location);
      // };
      //
      // xhr.onerror = function () {
      //   failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      // };

      const user = JSON.parse(JwtService.getToken());
      var token = user.token;

      if (typeof token === "undefined") {
        token = user.access_token;
      }

      formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      formData.append("type", this.type);
      
      const instance = axios.create({
        baseURL: "https://siuji.someah.id/",
        Authorization: `Bearer ${token}`,
      });

      console.log(instance);

      const response = await instance.post('uploads/',formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        }
      })

     
      // const response = await axios({
      //   method: "POST",
      //   url: "uploads/",
      //   baseURL: this.uploadBaseURL,
      //   data: formData,
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // const response = await ApiService.post("upload-image", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });

      console.log(response);
      if (response && response.status == 200) {
        setTimeout(function () {
      /* no matter what you upload, we will turn it into TinyMCE logo :)*/
        success(`${response.data.url}`);
        // success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
      }, 2000);
      } else {
        failure("HTTP Error: " + response.status, { remove: true });
      }

      // xhr.send(formData);
    },
  },
};
</script>
