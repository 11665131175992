// Mutation
export const SET_CLASS = "setClassList";
export const SET_ERROR = "setError";
export const STORE_CLASS = "storeClass";
export const GET_CLASS_LIST = "getClassList";
export const DELETE_CLASS = "deleteClass";
export const ASSIGN_CLASS = "assignClass";
export const DETAIL_CLASS = "detailClass";
export const SET_DETAIL_CLASS = "setDetailClass";

export const UPDATE_CLASS = "updateClass";



//teachers
export const TEACHER = "teachers";
export const UNASSIGN_TEACHER = "unassignTeacher"
export const GET_TEACHER_UNASSIGN = "getTeacherUnassign"
export const GET_TEACHER_ASSIGN = "getTeacherAssign"

export const SET_TEACHER_LIST = "setTeacherList";
export const SET_TEACHER_ASSIGN = "setTeacherAssign";

//student
export const STUDENT = "student";
export const UNASSIGN_STUDENT = "unassignStudent"
export const GET_STUDENT_UNASSIGN = "getStudentUnassign"
export const GET_LIST_STUDENT_CLASS = "getStudentClass";
export const SET_STUDENT_LIST = "setStudentList";
export const SET_UNASSIGN_STUDENT_LIST = "setUnassignStudentList";
export const ASSIGN_STUDENT_CLASS = "assignStudentClass";

export const CLEAR_CLASS_LIST = "clearClassList"






import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


const state = {
    classList: {

    },
    classDetail: {

    },
    teacherList: {

    },
    studentList: {

    },
    unassignStudentList: {

    },
    teacherAssign: {},
    errors: null,
};

const getters = {
    currentClassList(state) {
        return state.classList;
    },
    currentClassDetail(state) {
        return state.classDetail;
    },
    currentTeacherList(state) {
        return state.teacherList;
    },
    currentStudentList(state) {
        return state.studentList;
    },
    currentUnassignStudent(state) {
        return state.unassignStudentList;
    },
    currentAssignTeacherList(state) {
        return state.teacherAssign;
    }
};

const actions = {

    [GET_CLASS_LIST](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/paginate?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_CLASS, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [STORE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            const user = JSON.parse(JwtService.getToken())
            Object.assign(payload.body, {
                vendor_id: user.vendor_id
            })
            ApiService.post(`classes`, payload.body)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [ASSIGN_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`classes/assign/teacher`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },

    [DETAIL_CLASS](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_DETAIL_CLASS, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },

    [UNASSIGN_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`classes/unassign/teacher`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("classes/" + payload.class_id, payload.body).then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        })
    },
    [DELETE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("classes/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_TEACHER_UNASSIGN](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/paginate/teacher-no-assign?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_TEACHER_LIST, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_TEACHER_ASSIGN](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/paginate/teacher-assign?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_TEACHER_ASSIGN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_STUDENT_UNASSIGN](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/paginate/student-no-assign?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_UNASSIGN_STUDENT_LIST, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_LIST_STUDENT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('classes/paginate/student?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_STUDENT_LIST, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [ASSIGN_STUDENT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`classes/assign/student`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UNASSIGN_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`classes/unassign/student`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CLEAR_CLASS_LIST](context, payload) {
        context.commit(SET_CLASS, {})
    },

};


const mutations = {
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_CLASS](state, data) {
        state.classList = data;
    },
    [SET_DETAIL_CLASS](state, data) {
        state.classDetail = data;
    },
    [SET_TEACHER_LIST](state, data) {
        state.teacherList = data;
    },
    [SET_STUDENT_LIST](state, data) {
        state.studentList = data;
    },
    [SET_UNASSIGN_STUDENT_LIST](state, data) {
        state.unassignStudentList = data;
    },
    [SET_TEACHER_ASSIGN](state, data) {
        state.teacherAssign = data;
    },

};

export default {
    state,
    actions,
    mutations,
    getters,
};